<template>
    <div>
        <GlobalHeader :title="title" />
        <div class="container m-auto my-3">
            <div class="w-full bg-white-pure l-box-shadow rounded px-8 py-6 text-center">
                <h2
                    class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
                >{{getReason()}}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalHeader from '@/components/shared/GlobalHeader'
import {ErrorCodes} from '@/utils/enums'

export default {
    name: 'ErrorView',
    components: { GlobalHeader },
    data() {
        return {
            title: 'Error',
            type: this.$route.params.type
        }
    },
    methods: {
        getReason() {
            switch(this.type) {
                case ErrorCodes.IncorrectReferral:
                    return 'Ooops, looks like you got to this form without the code, please scan the QR code and try again'
                case ErrorCodes.IncorrectRouteParams:
                    return 'Ooops, looks like you got to this form without the code, please scan the QR code and try again'
                case ErrorCodes.FormSetNoLongerInUse:
                    return 'The Form you are accessing is no longer in use, please contact the practice to provide you with a new link'
                default:
                    return 'Something went wrong, these things happen, please contact the support team'
            }
        }
    }
}
</script>
